<template>
	<div class="formSub">
		<el-header class="header">
			<top-nav :seriesList="seriesList" :solutionList="solutionList" />
		</el-header>
		<div class="content" :style="{ backgroundImage: 'url(' + bgImg + ')' }">
			<div class="center">
				<h3>请填写您的信息，捷途慧声期待与您合作！</h3>
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="88px"
					class="demo-ruleForm"
					v-loading="loading"
				>
					<el-form-item label="公司名称" prop="companyName">
						<el-input
							v-model="ruleForm.companyName"
							placeholder="请输入公司名称"
						></el-input>
					</el-form-item>
					<el-form-item label="您的姓名" prop="name">
						<el-input
							v-model="ruleForm.name"
							placeholder="请输入您的姓名"
						></el-input>
					</el-form-item>
					<el-form-item label="联系电话" prop="phone">
						<el-input
							v-model="ruleForm.phone"
							placeholder="请输入您的联系电话"
						></el-input>
					</el-form-item>
					<el-form-item label="合作方式" prop="cooperation">
						<el-checkbox-group v-model="ruleForm.cooperation">
							<el-checkbox
								label="软件支持"
								name="cooperation"
							></el-checkbox>
							<el-checkbox
								label="硬件需求"
								name="cooperation"
							></el-checkbox>
							<el-checkbox
								label="项目合作"
								name="cooperation"
							></el-checkbox>
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="公司地址" prop="address">
						<el-input
							v-model="ruleForm.address"
							placeholder="请输入公司地址"
						></el-input>
					</el-form-item>
					<el-form-item label="您的需求" prop="desc">
						<el-input
							size="medium"
							type="textarea"
							v-model="ruleForm.desc"
							placeholder="请输入您的需求"
							class="resizeNone"
							maxlength="300"
							show-word-limit
							@input="limitLength(ruleForm.desc)"
						></el-input>
					</el-form-item>
					<p class="tip">
						<i class="el-icon-info"></i> 我们将在3个工作日内给您答复
					</p>
					<el-button class="submitBtn" @click="submitForm('ruleForm')"
						>完成</el-button
					>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
import TopNav from './childCpn/TopNav'
import { getMenuList } from '@/network/menu/index.js'
export default {
	name: 'formSub',
	data() {
		return {
			loading: false,
			bgImg: require('@/assets/img/formSub/webBg.jpg'),
			ruleForm: {
				companyName: '',
				name: '',
				phone: '',
				cooperation: [],
				address: '',
				desc: '',
			},
			rules: {
				companyName: [
					{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur',
					},
				],
				name: [
					{
						required: true,
						message: '请输入您的姓名',
						trigger: 'blur',
					},
				],
				phone: [
					{
						required: true,
						message: '请输入您的联系电话',
						trigger: 'blur',
					},
					{
						min: 1,
						max: 20,
						message: '联系电话长度为1-20位字符',
						trigger: 'blur',
					},
					{
						pattern: /^[-\d]+$/,
						message: '联系电话只能由数字、减号构成',
						trigger: 'blur',
					},
					// {
					// 	validator: function (rule, value, callback) {
					// 		let isMob =
					// 			/^(1[3|4|5|6|7|8|9])\d{9}$|^0\d{2,3}-?\d{7,8}$/
					// 		if (isMob.test(value) == false) {
					// 			callback(new Error('请输入正确的联系方式'))
					// 		} else {
					// 			callback()
					// 		}
					// 	},
					// },
				],
				cooperation: [
					{
						type: 'array',
						// required: true,
						message: '请选择合作方式',
						trigger: 'change',
					},
				],
				address: [
					{ required: false, message: '请输入地址', trigger: 'blur' },
				],
				desc: [{ required: false, message: '请填写', trigger: 'blur' }],
			},
		}
	},
	watch: {},
	mounted() {
		this.assignment()
		this._getMenuList()
	},
	methods: {
		assignment() {
			const form = sessionStorage.getItem('ruleForm')
			if (form) {
				this.ruleForm = JSON.parse(form)
				setTimeout(() => {
					sessionStorage.removeItem('ruleForm')
				}, 200)
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.loading = true
					let data = {
						company: this.ruleForm.companyName,
						name: this.ruleForm.name,
						phone: this.ruleForm.phone,
						cooperationMode: this.ruleForm.cooperation,
						address: this.ruleForm.address,
						message: this.ruleForm.desc,
					}
					this.axios
						.request({
							url: window.baseUrl + '/feedback',
							method: 'post',
							data,
						})
						.then((res) => {
							if (res.data.code === 200) {
								this.loading = false
								this.$message.success('发送成功')
								sessionStorage.removeItem('ruleForm')
								this.$refs[formName].resetFields()
								// setTimeout(() => {
								history.back()
								// }, 500)
							}
						})
						.catch(() => {
							this.loading = false
						})
				} else {
					return false
				}
			})
		},
		resetForm(formName) {
			this.$refs[formName].resetFields()
		},
		_getMenuList() {
			getMenuList().then((res) => {
				if (res.data.code == 200) {
					const { professionList, seriesList } = res.data.data

					this.seriesList = seriesList.map((item) => {
						let obj = Object.assign(item, {
							seriesIcon: window.baseUrl + item.seriesIcon,
						})
						return obj
					})
					this.solutionList = professionList
				}
			})
		},
		limitLength(value) {
			if (value.length >= 300) {
				this.$el.querySelector('.el-input__count').style =
					'color:#f95454 !important'
			} else {
				this.$el.querySelector('.el-input__count').style =
					'color:#909399 !important'
			}
		},
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem('ruleForm', JSON.stringify(this.ruleForm))
		next()
	},
	components: {
		TopNav,
	},
	computed: {
		isMobileEnd() {
			return this.$store.state.isMobileEnd
		},
		seriesList: {
			get() {
				return this.$store.state.seriesList
			},
			set(val) {
				this.$store.state.seriesList = val
			},
		},
		solutionList: {
			get() {
				return this.$store.state.solutionList
			},
			set(val) {
				this.$store.state.solutionList = val
			},
		},
	},

	destroyed() {},
}
</script>
<style lang="scss" scoped>
.formSub {
	.el-header {
		width: 100%;
		height: 0.6rem;
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		background: rgba($color: #000, $alpha: 0.2);
		z-index: 999;
	}
	.content {
		width: 100%;
		height: 9.14rem;
		position: relative;
		.center {
			width: 7.2rem;
			min-width: 450px;
			height: 7.6rem;
			position: absolute;
			left: 0;
			top: 0.6rem;
			right: 0;
			bottom: 0;
			margin: auto;
			background: #fff;
			border-radius: 0.1rem;
			padding: 0.85rem 1.32rem;
			box-sizing: border-box;
			.el-form {
				box-sizing: border-box;
				.el-form-item {
					margin-bottom: 0.2rem;
					::v-deep .el-form-item__label {
						height: 0.4rem;
						line-height: 0.4rem;
						padding: 0 0.2rem 0 0;
						color: #3f4875;
					}
				}

				::v-deep .el-input .el-input__inner {
					height: 0.4rem;
					line-height: 0.4rem;
					border: 1px solid #d5def3;
				}
			}
			h3 {
				font-size: 0.24rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 700;
				color: #4e5969;
				line-height: 0.36rem;
				margin-bottom: 0.4rem;
			}
			.tip {
				font-size: 0.14rem;
				font-family: Source Han Sans, Source Han Sans CN;
				font-weight: 400;
				color: #999999;
				line-height: 0.22rem;
				margin-bottom: 0.2rem;
				i {
					color: #ccc;
				}
			}
			.submitBtn {
				width: 100%;
				height: 0.4rem;
				font-size: 0.14rem;
				padding: 0;
				line-height: 0.4rem;
				background-color: #3573ff;
				color: #fff;
				border-radius: 0.04rem;
			}
		}
	}
}
.el-checkbox-group {
	text-align: left;
	::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
		color: #3573ff;
	}
	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #3573ff;
		border-color: #3573ff;
	}
	::v-deep .el-checkbox__label {
		color: #3f4875;
	}
}

.resizeNone {
	::v-deep .el-textarea__inner {
		//el_input中的隐藏属性
		height: 1.2rem;
		resize: none; //主要是这个样式
		border-color: #d5def3;
	}
}
@media screen and (max-width: 768px) {
	.formSub {
		.el-header {
			height: 44px !important;
		}
		.content {
			height: 812px;
			.center {
				margin-top: 60px;
				min-width: 354px;
				height: 652px;
				padding: 32px 14px;
				border-radius: 12px;
				.el-form {
					.el-form-item {
						margin-bottom: 20px;
						::v-deep .el-form-item__label {
							width: 80px !important;
							height: 36px;
							line-height: 36px;
							padding: 0 12px 0 0;
							color: #3f4875;
						}
					}

					::v-deep .el-input .el-input__inner {
						height: 36px;
						line-height: 36px;
						border: 1px solid #d5def3;
					}
				}
				h3 {
					font-size: 16px;
					margin-bottom: 20px;
					text-align: center;
				}
				.tip {
					font-size: 12px;
					line-height: 14px;
					margin-bottom: 20px;
				}
				.submitBtn {
					width: 100%;
					height: 40px;
					font-size: 14px;
					padding: 0;
					line-height: 40px;
					background-color: #3573ff;
					border-color: #3573ff;
					color: #fff;
					border-radius: 4px;
				}
			}
		}
	}
	.resizeNone {
		::v-deep .el-textarea__inner {
			//el_input中的隐藏属性
			height: 160px;
			resize: none; //主要是这个样式
		}
		::v-deep .el-input__count {
			line-height: 1;
			right: 12px;
			bottom: 12px;
		}
	}
}
</style>
